







































import {Component, Vue} from "vue-property-decorator";
import TextInput from "@/components/form/TextInput.vue";
import PasswordInput from "@/components/form/PasswordInput.vue";
import FormButton from "@/components/form/Button.vue";
import axios from "axios";

@Component({
  components: {
    TextInput,
    PasswordInput,
    FormButton,
  },
})
export default class PasswordSettings extends Vue {
  public oldPassword = "";
  public oldPasswordWarning = false;

  public newPassword = "";
  public newPasswordWarning = false;
  public newPasswordLengthWarning = false;

  public confirmationPassword = "";
  public confirmationPasswordWarning = false;

  public savingPassword = false;
  public success = false;
  public error = false;

  public savePassword() {
    this.success = false;
    this.error = false;

    if (this.validatePassword()) {
      this.savingPassword = true;
      axios.post("/profile/change-password", {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      }).then((res) => {
        this.oldPassword = "";
        this.newPassword = "";
        this.confirmationPassword = "";

        this.savingPassword = false;
        this.oldPasswordWarning = false;
        this.success = true;
      }).catch((err) => {
        if (err.response && err.response.status == 400) {
          this.oldPasswordWarning = true;
        } else {
          this.error = true;
        }
        this.savingPassword = false;
      });
    }
  }

  public validatePassword() {
    this.oldPasswordWarning = !this.oldPassword.trim();
    this.newPasswordWarning = !this.newPassword.trim() || this.newPassword.length < 10;
    this.newPasswordLengthWarning = this.newPassword.length < 10;
    this.confirmationPasswordWarning = !this.confirmationPassword.trim()
      || this.newPassword.trim() != this.confirmationPassword.trim();

    return !this.oldPasswordWarning && !this.newPasswordWarning
      && !this.newPasswordLengthWarning && !this.confirmationPasswordWarning;
  }
}
