


































import {Component, Vue} from "vue-property-decorator";
import TextInput from "@/components/form/TextInput.vue";
import FormButton from "@/components/form/Button.vue";
import axios from "axios";
import VueSelect from "vue-select";
import CheckboxInput from "@/components/form/CheckboxInput.vue";

@Component({
  components: {
    CheckboxInput,
    TextInput,
    FormButton,
    VueSelect,
  },
})
export default class ProfileSettings extends Vue {
  public name = "";
  public nameWarning = false;

  public city = "";
  public cityWarning = false;

  public country = "";
  public countryWarning = false;

  public institute = "";
  public instituteWarning = false;

  public emailAgreement = false;

  public savingProfile = false;
  public success = false;
  public error = false;

  public countries: Array<{ id: string, name: string }> = [];

  public created() {
    axios.get("/profile").then((res) => {
      this.name = res.data.user.name;
      this.city = res.data.user.city;
      this.country = res.data.user.country;
      this.institute = res.data.user.institute;
      this.emailAgreement = res.data.user.email_update_agreement;
    });

    axios.get("/countries").then((res) => {
      this.countries = res.data;
    });
  }

  public saveProfile() {
    this.success = false;
    this.error = false;

    if (this.validateProfile()) {
      this.savingProfile = true;
      axios.patch("/profile", {
        user: {
          name: this.name,
          city: this.city,
          institute: this.institute,
          country: this.country,
          email_update_agreement: this.emailAgreement,
        },
      }).then(() => {
        this.savingProfile = false;
        this.success = true;
      }).catch((err) => {
        if (err.response && err.response.status != 200) {
          this.error = true;
        }
        this.savingProfile = false;
      });
    }
  }

  public validateProfile() {
    this.nameWarning = !this.name.trim();
    this.cityWarning = !this.city.trim();
    this.countryWarning = !this.country.trim();
    this.instituteWarning = !this.institute.trim();

    return !this.nameWarning && !this.cityWarning && !this.countryWarning && !this.instituteWarning;
  }
}
