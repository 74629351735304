














import {Component, Vue} from "vue-property-decorator";
import ProfileSettings from "@/components/profile/ProfileSettings.vue";
import PasswordSettings from "@/components/profile/PasswordSettings.vue";
import AccountDeletionSettings from "@/components/profile/AccountDeletionSettings.vue";

@Component({
  components: {
    AccountDeletionSettings,
    PasswordSettings,
    ProfileSettings,
  },
})
export default class Profile extends Vue {

}
