



































import {Component, Vue} from "vue-property-decorator";
import PasswordInput from "@/components/form/PasswordInput.vue";
import FormButton from "@/components/form/Button.vue";
import axios from "axios";
import ProfileSettings from "@/components/profile/ProfileSettings.vue";
import PasswordSettings from "@/components/profile/PasswordSettings.vue";

@Component({
  components: {
    PasswordSettings,
    ProfileSettings,
    PasswordInput,
    FormButton,
  },
})
export default class AccountDeletionSettings extends Vue {
  public deletionPassword = "";
  public deletionPasswordWarning = false;

  public error = false;

  public validateDeletionPassword() {
    this.deletionPasswordWarning = !this.deletionPassword.trim();
    return !this.deletionPasswordWarning;
  }

  public deleteAccount() {
    this.deletionPasswordWarning = false;
    this.error = false;

    if (this.validateDeletionPassword()) {
      axios.post("/profile/delete", {
        password: this.deletionPassword,
      }).then(() => {
        this.$store.dispatch("authLogout").then(() => {
          this.$router.push("/login");
        });
      }).catch((err) => {
        if (err.response && err.response.status == 400) {
          this.deletionPasswordWarning = true;
        } else {
          this.error = true;
        }
      });
    }
  }
}
